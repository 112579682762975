import React from 'react'
import { Webglmetaverse } from './Webglmetaverse'

const Metaverse = () => {
  return (
    // <div className="w-full h-screen flex items-center justify-center"> {/* Apply Tailwind CSS classes */}
    //   <iframe
    //     title="Unity WebGL App"
    //     src="/hexawebgl/index.html"
    //     className="w-full h-full"
    //     style={{ border: 'none' }} // Optional: Remove the border
    //   />
    // </div>
    <div>
        <Webglmetaverse/>
    </div>
  )
}

export default Metaverse