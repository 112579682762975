import React from "react";
import Hexnavbar from "../Partial/Hexnavbar";
import Para from "../comphex/Para";
import Search from "../comphex/Search";
import Interior from "../comphex/Interior";
import Product from "../comphex/Product";
import Hexfooter from "../Partial/Hexfooter";
import Hexbrand from "../comphex/Hexbrand";
//import Botai from "../comphex/Botai";


const Home = () => {
  return (
    <>
      <Hexnavbar />
      <Para />
      <Search />
      <Product />
      <Interior />
      {/* <Botai/> */}
      <Hexbrand />
      <Hexfooter />
    </>
  );
};

export default Home;
