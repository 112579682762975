import React, { useEffect, useRef, useState } from 'react';
import gambar from '../assets/pict/Hexaverse/interior.jpg'
import Untar from '../assets/pict/class/Untar.jpg'
import Debian from '../assets/pict/Hexaverse/debian.jpg'
import Scandinavian from '../assets/pict/Hexaverse/scandi.jpg'
import { NavLink } from "react-router-dom";
const Product= () => {
  const [inViewport, setInViewport] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      setInViewport(entry.isIntersecting);
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  return (

    <div className='text-[#000] py-24'>
    <div className='max-w-[900px] mt-[-96px] w-full h-700px mx-auto text-center flex flex-col justify-center'>

            <h2 className='md:text-5xl sm:text-3xl text-1xl text-[#000] md:py-6'>
              Hexaverse Series Product
            </h2>
      <div className='flex justify-center items-center'>
        <p >
        Tipe produk rumah dengan desain elegant menawarkan keanggunan yang tak tertandingi dengan material dan furnitur berkualitas tinggi.
        </p>
      </div>
    </div>
    <div className={`${
            inViewport ? 'opacity-100 translate-y-0 transition-all duration-1000' : 'opacity-0 translate-y-12'
          }`}
          ref={sectionRef}>
    <div className='max-w-[1240px] mx-auto my-5 grid md:grid-cols-4'>
        <div className="morph group relative h-[300px] mx-5 my-5 cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30 rounded-lg">
          <img className='w-full h-full  object-cover mx-auto  rounded-lg' src={Untar} alt='/' />
          
          <div className="absolute inset-0 bg-gradient-to-b from-black/30 to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
          <div className="absolute inset-0 flex translate-y-[40%] flex-col items-center justify-center  text-center transition-all duration-500 group-hover:translate-y-0">
            <h1 className=" text-3xl  text-white">Cluster <br/> Hexantium </h1>
            <p className="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">Beyond Marketing Gallery</p>
           
            <div className=' py-6'>
            <NavLink
             to="/login"
            className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60 border-white hover:bg-white hover:text-black">
              Jelajahi Metaverve
            </NavLink>
            </div>
          </div>
        </div>

        <div className="morph group relative h-[300px] mx-5 my-5 cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30 rounded-lg">
          <img className='w-full h-full  object-cover mx-auto  rounded-lg' src={Scandinavian} alt='/' />
          
          <div className="absolute inset-0 bg-gradient-to-b from-black/30 to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
          <div className="absolute inset-0 flex translate-y-[40%] flex-col items-center justify-center  text-center transition-all duration-500 group-hover:translate-y-0">
          <h1 className=" text-3xl  text-white">Cluster <br/> Scandinavian </h1>
            {/* <p className="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis dolore adipisci placeat.</p> */}
            
            <div className=' py-6'>
            <NavLink
             to="/login"
            className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60 border-white hover:bg-white hover:text-black">
              Jelajahi Multiplayer
            </NavLink>
            </div>
          </div>
        </div>
        <div className="morph group relative h-[300px] mx-5 my-5 cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30 rounded-lg">
          <img className='w-full h-full  object-cover mx-auto  rounded-lg' src={Debian} alt='/' />
          
          <div className="absolute inset-0 bg-gradient-to-b from-black/30 to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
          <div className="absolute inset-0 flex translate-y-[40%] flex-col items-center justify-center  text-center transition-all duration-500 group-hover:translate-y-0">
          <h1 className=" text-3xl  text-white">Cluster <br/> Debian </h1>
            {/* <p className="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis dolore adipisci placeat.</p> */}
            
            <div className=' py-6'>
            <NavLink
             to="/login"
            className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60 border-white hover:bg-white hover:text-black">
              Jelajahi Metaverve
            </NavLink>
            </div>
          </div>
        </div>
        <div className="morph group relative h-[300px] mx-5 my-5 cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30 rounded-lg">
          <img className='w-full h-full  object-cover mx-auto  rounded-lg' src={gambar} alt='/' />
          
          <div className="absolute inset-0 bg-gradient-to-b from-black/30 to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
          <div className="absolute inset-0 flex translate-y-[40%] flex-col items-center justify-center  text-center transition-all duration-500 group-hover:translate-y-0">
          <h1 className=" text-3xl  text-white">Virtiual <br/> 360 Tour </h1>
            {/* <p className="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis dolore adipisci placeat.</p> */}
            
            <div className=' py-6'>
            <NavLink
             to="/Virtual"
            className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60 border-white hover:bg-white hover:text-black">
              Virtual Tour
            </NavLink>
            </div>
          </div>
        </div>
    </div>

    
    </div>

  </div>
  )
}

export default Product
