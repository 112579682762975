import React, { useEffect, useRef, useState } from 'react';
import Interiors from './Interiors';
import { NavLink } from "react-router-dom";
const Interior = () => {
  const [inViewport, setInViewport] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      setInViewport(entry.isIntersecting);
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const handleScroll = () => {
    const scrollValue = window.scrollY;
    const parallaxValue = scrollValue * 0.1; // Adjust the parallax speed here (smaller value for slower parallax)
    const parallaxElement = document.getElementById('parallax-img');

    if (parallaxElement) {
      parallaxElement.style.transform = `translateX(${parallaxValue}px)`;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className='w-full  py-5 px-4'>
        <div
          className='max-w-[1240px] mx-auto grid md:grid-cols-2'
        >
          <div className={`flex flex-col justify-center px-4${
            inViewport ? 'opacity-100 translate-y-0 transition-all duration-1000' : 'opacity-0 -translate-x-12'
          }`}
          ref={sectionRef}>
            {/* Your existing content */}
            <h2 className='md:text-5xl sm:text-3xl text-1xl text-[#000] md:py-6'>
              Virtual Tour 360
            </h2>
            <br />
            <p className='text-[#000]'>
            memungkinkan Anda menjelajahi dan merancang ruang interior dengan tingkat interaktivitas lebih realistis. 
            Anda dapat lebih mudah mengenali setiap sudut ruangan, melihat furnitur, dan merencanakan dekorasi dengan 
            detail yang luar biasa. 
            </p>
            
            <NavLink
              to="/Hexaverse/Virtual"
              className='bg-teal-900 hover:bg-[#fff] hover:text-teal-900 border-0 rounded-lg font-medium my-6 py-3 px-8 text-[#fff]  mr-auto'>
              Mulai Tour
            </NavLink>
          </div>
          
         
         
          <Interiors />
        </div>
       

      </div>
    </>
  );
};

export default Interior;
