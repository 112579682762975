import React from "react";
import Hexnavbar from "../Partial/Hexnavbar";
import { ReactPhotoSphereViewer, CompassPlugin, MarkersPlugin,GalleryPlugin,VirtualTourPlugin } from 'react-photo-sphere-viewer';
import gambar from '../assets/pict/Hexaverse/interior.jpg';
import Hexfooter from "../Partial/Hexfooter";

function Virtual() {
  // Define the plugins array
  const plugins = [
    [CompassPlugin, {
      hotspots: [
        { longitude: '45deg',yaw:'45deg' },
        { longitude: '60deg',yaw:'60deg' },
        ],}],
        MarkersPlugin,
        [GalleryPlugin, {
            thumbnailSize: { width: 100, height: 100 },
        }],
        [VirtualTourPlugin, {
            positionMode: 'gps',
            renderMode: '3d',
        }],
  ];
  // const virtualTour = viewer.getPlugin(VirtualTourPlugin);
  // const markerLighthouse = {
  //   id: 'marker-1',
  //   image: baseUrl + 'pictos/pin-red.png',
  //   tooltip: 'Cape Florida Light, Key Biscayne',
  //   size: { width: 32, height: 32 },
  //   anchor: 'bottom center',
  //   gps: [-80.155973, 25.666601, 29 + 3],
  // };
  const handleReady = (instance) => {
    const markersPlugs = instance.getPlugin(MarkersPlugin);
    if (!markersPlugs) {
      return;
    }
    // markersPlugs.addMarker({
    //   id: 'imageLayer2',
    //   imageLayer: 'drone.png',
    //   size: { width: 220, height: 220 },
    //   position: { yaw: '130.5deg', pitch: '-0.1deg' },
    //   tooltip: 'Image embedded in the scene',
    // });
    // markersPlugs.addEventListener('select-marker', () => {
    //   console.log('asd');
    // });
  }

  return (
    <div className="">
      <Hexnavbar />
      <div className='pt-5 '>
        <ReactPhotoSphereViewer src={gambar} plugins={plugins} height={'100vh'} width={'100%'} onReady={handleReady} />
      </div>
      <Hexfooter />
    </div>
  );
}

export default Virtual;