import React, { useEffect } from 'react';
// import hill1 from '../assets/pict/para/hill1.png';
// import hill2 from '../assets/pict/para/hill2.png';
// import hill3 from '../assets/pict/para/hill3.png';
import hill4 from '../assets/para/hill4.png';
import hill5 from '../assets/para/hill5.png';
import plant from '../assets/para/plant.png';
import Carousel from './Carousel';
// import Slider from '../component/Slider';
import Search from './Search';
import Debian from '../assets/pict/Hexaverse/debian.jpg'
import Ident2 from './Ident2';
const Para = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const parallaxElements = document.querySelectorAll('.parallax-element');

      parallaxElements.forEach((element) => {
        const speed = parseFloat(element.getAttribute('data-speed'));
        const direction = element.getAttribute('data-direction');
        let movement;

        if (direction === 'left') {
          movement = scrollPosition * speed;
        } else if (direction === 'right') {
          movement = -scrollPosition * speed;
        } else if (direction === 'top') {
          movement = -scrollPosition * speed;
        } else if (direction === 'scale') {
          movement = 1 + (scrollPosition * speed) / 1000;
        }

        element.style.transform = `translateX(${movement}px)`;
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`parallax-container bg-${Debian} relative w-full h-full overflow-hidden`}>
      <div className="parallax-content absolute top-0 left-0 w-full">
        {/* <div>
          <Slider/>
        </div> */}
        {/* <div className="carousel-wrapper">
          <Carousel />
        </div> */}
        <img src={hill4} alt="Image 3" className="parallax-element" data-speed="0.5" data-direction="right" /> 
        <img src={hill5} alt="Image 4" className="parallax-element" data-speed="0.6" data-direction="left" />
        <img src={plant} alt="Image 5" className="parallax-element" data-speed="0.8" data-direction="bottom" />
        <Ident2/>
        {/* <Search/> */}
      </div>
    </div>
  );
};

export default Para;