import React from 'react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles

import Hexantium from '../assets/pict/logo/Hexantium.png';
import Bahaso from '../assets/pict/logo/Bahaso.png';
//import Wir from '../assets/pict/logo/Wir.png';
import Ub from '../assets/pict/logo/Ub.png';
import Untar from '../assets/pict/logo/Untar.png';

SwiperCore.use([Autoplay, Navigation]); // Enable Swiper Autoplay and Navigation

const Hexbrand = () => {
  const isMobile = window.innerWidth < 768; // Set the breakpoint for mobile here (768px in this example)

  return (
    <div className="bg-neutral-900 flex items-center justify-center h-[400px]">
       <Swiper
        spaceBetween={30}
        slidesPerView={isMobile ? 1 : 3} // Display 1 slide in mobile mode, 3 slides for larger screens
        speed={12000}
        loop={true} // Enable infinite loop
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        // navigation={true} /* Enable navigation arrows */
       
       
      >
        {/* Add more slides to create the infinite loop */}
        <SwiperSlide>
          <div className="flex justify-center">
            <div className="avatar-gradient bg-neutral-700 px-2 py-5 w-[550px] rounded-[30px] flex justify-center ">
              <img src={Hexantium} alt="Features split 01" className="avatar-image h-[200px]" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center">
            <div className="avatar-gradient bg-neutral-700 px-2 py-5 w-[550px] rounded-[30px] flex justify-center ">
              <img src={Bahaso} alt="Features split 01" className="avatar-image h-[200px]" />
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className="flex justify-center">
            <div className="avatar-gradient bg-neutral-700 px-2 py-5 w-[550px] rounded-[30px] flex justify-center ">
              <img src={Wir} alt="Features split 01" className="avatar-image h-[200px]" />
            </div>
          </div>
        </SwiperSlide> */}
        <SwiperSlide>
          <div className="flex justify-center">
            <div className="avatar-gradient bg-neutral-700 px-2 py-5 w-[550px] rounded-[30px] flex justify-center ">
              <img src={Ub} alt="Features split 01" className="avatar-image h-[200px]" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center">
            <div className="avatar-gradient bg-neutral-700 px-2 py-5 w-[550px] rounded-[30px] flex justify-center ">
              <img src={Untar} alt="Features split 01" className="avatar-image h-[200px]" />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Hexbrand;
