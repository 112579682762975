import React, { Fragment, useState, useEffect, useRef } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { NavLink } from "react-router-dom";
import "../local/css/webgl.css";
// import Navbar from "../Partial/Navbar";

export function Webglmetaverse() {
  const { unityProvider, loadingProgression, isLoaded } = useUnityContext({

    // loaderUrl: "../hexawebgl/Build/test/TestWebGL.loader.js",
    // dataUrl: "../hexawebgl/Build/test/TestWebGL.data",
    // frameworkUrl: "../hexawebgl/Build/test/TestWebGL.framework.js",
    // codeUrl: "../hexawebgl/Build/test/TestWebGL.wasm",

    loaderUrl: "../hexawebgl/Build/webgl.loader.js",
    dataUrl: "../hexawebgl/Build//webgl.data",
    frameworkUrl: "../hexawebgl/Build/webgl.framework.js",
    codeUrl: "../hexawebgl/Build/webgl.wasm",
  });

  // const [fullscreen, setFullscreen] = useState(false);
  const unityRef = useRef(null);
  const [isLogin, setIsLogin] = useState(false);

  // toggle fullscreen function
  // const toggleFullscreen = () => {
  //   if (!fullscreen) {
  //     if (unityRef.current.requestFullscreen) {
  //       unityRef.current.requestFullscreen();
  //     } else if (unityRef.current.mozRequestFullScreen) {
  //       // Firefox
  //       unityRef.current.mozRequestFullScreen();
  //     } else if (unityRef.current.webkitRequestFullscreen) {
  //       // Chrome, Safari and Opera
  //       unityRef.current.webkitRequestFullscreen();
  //     } else if (unityRef.current.msRequestFullscreen) {
  //       // IE/Edge
  //       unityRef.current.msRequestFullscreen();
  //     }
  //   } else {
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen();
  //     } else if (document.mozCancelFullScreen) {
  //       // Firefox
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitExitFullscreen) {
  //       // Chrome, Safari and Opera
  //       document.webkitExitFullscreen();
  //     } else if (document.msExitFullscreen) {
  //       // IE/Edge
  //       document.msExitFullscreen();
  //     }
  //   }
  //   setFullscreen(!fullscreen);
  // };

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  // add this useEffect

  // useEffect(() => {
  //   const changeHandler = () => {
  //     setFullscreen(document.fullscreenElement !== null);
  //   };

  //   document.addEventListener("fullscreenchange", changeHandler);
  //   document.addEventListener("webkitfullscreenchange", changeHandler);
  //   document.addEventListener("mozfullscreenchange", changeHandler);
  //   document.addEventListener("MSFullscreenChange", changeHandler);

  //   return () => {
  //     document.removeEventListener("fullscreenchange", changeHandler);
  //     document.removeEventListener("webkitfullscreenchange", changeHandler);
  //     document.removeEventListener("mozfullscreenchange", changeHandler);
  //     document.removeEventListener("MSFullscreenChange", changeHandler);
  //   };
  // }, []);

  return (
    <Fragment>
      {/* <div className="bg-screen"> */}
      {isLogin ? (
        <Fragment>
          {!isLoaded && (
            <p
              className="fullscreen-loading"
              // style={{
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "center",
              //   height: "100vh",
              // }}
            >
              Loading Application... {Math.round(loadingProgression * 100)}%
            </p>
          )}
          {/* <Navbar /> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100vw",
            }}
          >
            <div
              ref={unityRef} // add this
              style={{
                visibility: isLoaded ? "visible" : "hidden",
                margin: "0 auto",
                width: "100%",
                height: "100%",
              }}
            >
              <Unity
                unityProvider={unityProvider}
                style={{
                  visibility: isLoaded ? "visible" : "hidden",
                  // width: fullscreen ? "100%" : "1280px",
                  // height: fullscreen ? "100%" : "720px",
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
          {/* button fullscreen */}
          {/* <div className="fixed bottom-0 right-0 m-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                onClick={toggleFullscreen}
              >
                Fullscreen
              </button>
            </div> */}
        </Fragment>
      ) : (
        <>
          <b
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            Anda belum login !
            <NavLink to={"/login"}>
              <button
                className=" text-white font-bold py-2 px-4 ml-3 rounded-full"
                //   onClick={() => <NavLink to="/messages" />}
              >
                Login
              </button>
            </NavLink>
          </b>
        </>
      )}
      {/* </div> */}
    </Fragment>
  );
}
