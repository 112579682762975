import React from "react";
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa";

const Hexfooter = () => {
  return (
    <div className="bg-[#000]">
      <div className="mx-auto w-full max-w-[1240px] 4xl:max-w-screen-4xl px-3 sm:px-6 md:px-12 lg:px-[5.75rem] grid auto-rows-auto gap-5 py-20 md:gap-8"> 
      <div className="border border-gray-400"></div>
       <div className="grid justify-between max-w-[1240px] mx-auto  px-4 lg:grid-cols-3 gap-8 text-gray-500 ">
      
      <div className=" w-[40vh]">
      
        <h1 className="w-full text-3xl font-bold text-[#fff]">
         Hexaverse.
        </h1>
        <div className="flex justify-between md:w-[75%] md:text-center my-6">
          <FaFacebookSquare size={30} />
          <FaInstagram size={30} />
          <FaTwitterSquare size={30} />
          <FaGithubSquare size={30} />
          <FaDribbbleSquare size={30} />
        </div>
        <div className="lg:col-span-2 flex justify-between mt-6">
          <div>
            <ul>
              <li className="py-2 text-sm">Tentang Kami</li>
              <li className="py-2 text-sm">Kontak</li>
              <li className="py-2 text-sm">Privasi</li>
              <li className="py-2 text-sm">Terms</li>
            </ul>
          </div>

          <div>
            <ul>
              <li className="py-2 text-sm">WebGL Toolkit</li>
              <li className="py-2 text-sm">Guideline Komunitas</li>
              <li className="py-2 text-sm">Dokumentasi</li>
              <li className="py-2 text-sm">Support</li>
              <li className="py-2 text-sm">Pricing</li>
            </ul>
          </div>

          <div>
            <ul>
              <li className="py-2 text-sm">Bikin Avatar</li>
              <li className="py-2 text-sm">Katulistiwa.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="lg:col-span-2  justify-between  relative">
        <div className="absolute bottom-0 right-0">
          © 2023 HEXACODE, Inc. All Rights Reserved.
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Hexfooter;
