import React, {useEffect,useState} from 'react'
import { FiEye, FiEyeOff } from "react-icons/fi";
import axios from "axios";
import config from '../config/host';
// import { generateRandomString } from "../utils/random_string";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";


function Register () {
    //form fill from asstestroe
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullname, setFullname] = useState("");
    const [birthday, setBirthday] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    
    //create recaptcha
    const recaptchaRef = React.createRef();
    const navigate = useNavigate();

    //navigate to webgl
    useEffect(() => {
        if (sessionStorage.getItem("token") !== null) {
          navigate("/Metaverse");
        }
      }, [navigate]);

    //submit function
    const handleSubmit = async (event) => {
        event.preventDefault();

        const token = await recaptchaRef.current.executeAsync();
        
        if (token === null) {
          setAlert(true);
          setAlertType("error");
          setAlertMessage("Mohon verifikasi reCAPTCHA!");
          return;
        }

        // console.log(token);
        const data = {
          username: username,
          email: email,
          password: password,
          fullname: fullname,
          birthday: birthday,
          address: address,
          city: city,
          country: country,
        };
        axios
          .post(
            `${config.API_BASE_URL}/api/auth/local/register`,
            JSON.stringify(data),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((res) => {
            setAlert(true);
            setAlertType("success");
            setAlertMessage("Berhasil mendaftar!");
            // console.log(res);
            // console.log(res.data);
            sessionStorage.setItem("token", res.data.jwt);
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            navigate("/Metaverse");
          })
          .catch((err) => {
            setAlert(true);
            setAlertType("error");
            setAlertMessage(err.response.data.error.message);
            console.log(err);
          });
    
        // handle form submission logic here
      };

    //username function
    const handleUsernameChange = (e) => {
        let input = e.target.value;
        let regex = /^[a-zA-Z0-9]+$/; // alphanumeric (letters and numbers) only
    
        if (regex.test(input)) {
          if (input.length <= 30) {
            setUsername(input);
          }
          // setUsername(input);
        }
    };

    //email function
    const handleEmailChange = (event) => {
        const input = event.target.value.replace(/[^a-zA-Z0-9@.-_]/g, "");
        if (input.length <= 30) {
          setEmail(input);
        }
      };
    
    //pwd function  
    const handlePasswordChange = (event) => {
        const input = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
        if (input.length <= 30) {
          setPassword(input);
        }
      };
    
    //pass visibility
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
      };



      return(
        <div className="bg-loginbg bg-cover bg-no-repeat bg-center min-h-screen flex items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
            <div className=" bordershadow bg-black bg-opacity-30 backdrop-filter backdrop-blur-lg py-20 px-10  rounded-3xl border-sky-400 border-[1px]  fill-teal-50 max-w-md w-full space-y-8">
                    {alert && (
                            <div
                                className={
                                alertType === "success"
                                    ? "bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                                    : "bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                }
                                role="alert"
                            >
                            <strong className="font-bold">Pesan! </strong>
                            <span className="block sm:inline">{alertMessage}</span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                            <svg
                                className={
                                alertType === "success"
                                    ? "fill-current h-6 w-6 text-green-500"
                                    : "fill-current h-6 w-6 text-red-500"
                                }
                                // className="fill-current h-6 w-6 text-red-500"
                                role="button"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <title>Close</title>
                                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                            </svg>
                            </span>
                        </div>
                    )}
            <div>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
                    Daftar Akun Katulistiwa
                </h2>
            </div>
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md -space-y-px">
                <div className="py-1">
                <label htmlFor="username" className="sr-only">
                    Nama Pengguna
                </label>
                <input
                    id="username"
                    name="username"
                    type="text"
                    autoComplete="username"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Username"
                    value={username}
                    onChange={handleUsernameChange}
                />
                </div>
                <div className="py-1">
                <label htmlFor="email-address" className="sr-only">
                    Email address
                </label>
                <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Email address"
                    value={email}
                    onChange={handleEmailChange}
                />
                </div>
                <div className="py-1">
                <label htmlFor="password" className="sr-only">
                    Password
                </label>
                <div className="relative">
                    <input
                    id="password"
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm pr-10" // add pr-10 here
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    />
                    <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 pl-3 text-sm leading-5"
                    >
                    {passwordVisible ? (
                        <FiEyeOff
                        color="black"
                        size="16px"
                        className="self-center"
                        />
                    ) : (
                        <FiEye color="black" size="16px" className="self-center" />
                    )}
                    </button>
                </div>
                </div>
                <div className="py-1">
                <label htmlFor="fullname" className="sr-only">
                    Nama Lengkap
                </label>
                <input
                    id="fullname"
                    name="fullname"
                    type="text"
                    autoComplete="current-fullname"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Nama Lengkap"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                />
                </div>
                <div className="py-1">
                <label htmlFor="birthday" className="sr-only">
                    Tanggal Lahir
                </label>
                <input
                    id="birthday"
                    name="birthday"
                    type="date"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    value={birthday}
                    onChange={(e) => setBirthday(e.target.value)}
                />
                </div>
                <div className="py-1">
                <label htmlFor="address" className="sr-only">
                    Alamat Lengkap
                </label>
                <input
                    id="address"
                    name="address"
                    type="text"
                    autoComplete="current-address"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Alamat Lengkap"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                </div>
                <div className="py-1">
                <label htmlFor="city" className="sr-only">
                    Kota
                </label>
                <input
                    id="city"
                    name="city"
                    type="text"
                    autoComplete="current-city"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Kota"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                />
                </div>
                <div className="py-1">
                <label htmlFor="country" className="sr-only">
                    Negara
                </label>
                <input
                    id="country"
                    name="country"
                    type="text"
                    autoComplete="current-country"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Negara"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                />
                </div>
                <div className="py-1">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6Ldzqz8nAAAAAJ8D9hntippbcnoq2yNBxXf_SduL"
                />
                </div>
            </div>

            <div className="space-y-2">
                <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-[#14cae6] to-blue-500  hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                Daftar
                </button>

                <button
                onClick={() => navigate("/login")}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-[#25f58d] to-[#0da12d] hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                Kembali
                </button>
                {/* <button
                onClick={testingSubmit}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                Testing
                </button> */}
            </div>
            </form>
            </div>
        </div>
      )
}

export default Register