import React from 'react';
import Carouselb from './Carouselb';

const slides = [
  require('../assets/videos/v_education.mp4'),
  require('../assets/videos/v_multi.mp4'),
  require('../assets/videos/v_integration.mp4'),
];

export default function Carousel() {
  return (
    <main className='w-full h-full'>
      <div className=' w-full  h-full bg-black object-cover'>
        <Carouselb autoSlide autoSlideInterval={5000} >
          {slides.map((s, index) => (
            <video
              key={index}
              src={s}
              autoPlay
              muted
              loop
              className="carousel w-full max-h-full object-cover  opacity-70"
            />
            
          ))}
        </Carouselb>
      </div>
      
    </main>
  );
}