import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import config from "../config/host";
import { useNavigate } from "react-router-dom";
import kat from "../assets/pict/logo/kat2.png";
import kat2 from "../assets/pict/logo/kat.png";

function LoginForm(){
    //form
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [alert, setAlert] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    
    //define usenavigate as navigate
    const navigate = useNavigate();

    //navigate to webgl 
    useEffect(() => {
        if (sessionStorage.getItem("token")) {
          navigate("/Metaverse");
        }
      }, [navigate]);

    //handle submit
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const data = {
          identifier: email,
          password: password,
        };
    
        await axios
          .post(`${config.API_BASE_URL}/api/auth/local`, JSON.stringify(data), {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            setAlert(true);
            setAlertType("success");
            setAlertMessage("Berhasil Login!");
            console.log(res);
            console.log(res.data);
            sessionStorage.setItem("token", res.data.jwt);
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            navigate("/Metaverse");
          })
          .catch((err) => {
            setAlert(true);
            setAlertType("error");
            setAlertMessage(err.response.data.error.message);
            console.log(err);
          });
        // handle your form submission logic here
      };

    //hoverswitch image effect  
    const [isHovered, setIsHovered] = useState(false);
    const handleHover = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
    
      const handleGuest = () => {
        console.log("guest");
        sessionStorage.setItem("token", "123");
        sessionStorage.setItem("user", "guest");
        navigate("/Metaverse");
      };

      const imageSrc = isHovered ? kat : kat2;

    return(
        <div className="bg-loginbg bg-cover bg-no-repeat bg-center min-h-screen flex items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
            <div className=" bordershadow bg-black bg-opacity-30 backdrop-filter backdrop-blur-lg py-20 px-10  rounded-3xl border-sky-400 border-[1px]  fill-teal-50 max-w-md w-full space-y-8">
        {alert && (
          <div
            className={
              alertType === "success"
                ? "bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                : "bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            }
            role="alert"
          >
            <strong className="font-bold">Pesan! </strong>
            <span className="block sm:inline">{alertMessage}</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <svg
                className={
                  alertType === "success"
                    ? "fill-current h-6 w-6 text-green-500"
                    : "fill-current h-6 w-6 text-red-500"
                }
                // className="fill-current h-6 w-6 text-red-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        )}
        <div className=" h-[80px]  flex justify-center ">
          {/* <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Katulistiwa
          </h2> */}
          <div className="bordershadow  h-[80px] my-[-20px] rounded-full">
            <Link to="/">
              <img
                className={`h-[80px] transition-transform transform-gpu ${
                  isHovered ? "-rotate-45" : ""
                }`}
                src={imageSrc}
                alt="Katulistiwa"
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                style={{ transition: "transform 0.3s ease" }}
              />
            </Link>
          </div>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="py-1">
              <label htmlFor="email-address" className="sr-only">
                Alamat Email
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Alamat Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="py-1">
              <label htmlFor="password" className="sr-only">
                Kata Sandi
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Kata Sandi"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="space-y-4">
            <button
              type="submit"
              className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-[#14cae6] to-blue-500 hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
            >
              Masuk
            </button>
            <div></div>
            <NavLink to={"/register"}>
              <button className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-[#25f58d] to-[#0da12d]  hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 w-full">
                Daftar
              </button>
            </NavLink>
            <button
              onClick={() => handleGuest()}
              className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-[#2193b0] to-[#6dd5ed]  hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full"
            >
              Login as Guest
            </button>
          </div>
        </form>
      </div>
        </div>
    )
}

export default LoginForm