// export default {
//   API_BASE_URL: "https://assetstore.katulistiwa.io",
// };

const config = {
  API_BASE_URL: "https://assetstore.katulistiwa.io",
};

// const config = {
//   API_BASE_URL: "localhost:3000",
// };
export default config;
