import React from "react";

const Search = () => {
  return (
    <div className="text-[#000] py-40">
      <div className="max-w-[1200px] mt-[-96px] w-full h-700px mx-auto text-center flex flex-col justify-center">
        <h2 className="md:text-5xl sm:text-3xl text-1xl text-[#000] md:py-6">
          Temukan Properti Terbaik Anda
        </h2>
        <div className="flex flex-col justify-center items-start bg-gray-300 rounded px-4 py-4">
          <div className="flex items-start justify-start">
            <h3 className="md:text-3xl sm:text-5xl text-4xl text-[#000] font-semibold md:py-6">
              Cari Lokasi
            </h3>
          </div>
          <div className="flex w-full rounded bg-gray-300 space-x-3 ">
            <label className="relative cursor-pointer w-full">
              <input
                type="text"
                placeholder="Input"
                className=" w-full h-20 px-4 py-1  text-teal-900 bg-gray-300 border-teal-900 border-2 rounded-lg border-opacity-50 outline-none focus:border-blue-500 placeholder-red-300 placeholder-opacity-0 transition duration-200"
              />
              <span className=" text-2xl text-teal-900 text-opacity-80 bg-gray-300 absolute left-5 top-5 px-1 transition duration-200 input-text">
                Pilih Lokasi
              </span>
            </label>
            <button className=" w-1/4 rounded bg-teal-900 px-4 py-1 text-white">
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
