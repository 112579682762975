import React from "react";
import Home from "./views/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Metaverse from "./views/Metaverse";
import Virtual from "./comphex/Virtual";
import Register from "./views/Register";
import LoginForm from "./views/Login";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="Home" element={<Home />} />
            <Route path="metaverse" element={<Metaverse/>}/>           
            <Route path="virtual" element={<Virtual/>}/>

            <Route path="login" element={<LoginForm/>}/>
            <Route path="register" element={<Register/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
