import React, { useEffect, useRef, useState } from 'react';

import { NavLink } from "react-router-dom";

const Ident2 = () => {
  const [inViewport, setInViewport] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      setInViewport(entry.isIntersecting);
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const handleScroll = () => {
    const scrollValue = window.scrollY;
    const parallaxValue = scrollValue * 0.1; // Adjust the parallax speed here (smaller value for slower parallax)
    const parallaxElement = document.getElementById('parallax-img');

    if (parallaxElement) {
      parallaxElement.style.transform = `translateX(${parallaxValue}px)`;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className='w-full py-5 px-4'>
        <div
          className='max-w-[1240px] mx-auto grid md:grid-cols-2'
        >
          <div className={`flex flex-col justify-center px-4${
            inViewport ? 'opacity-100 translate-y-0 transition-all duration-1000' : 'opacity-0 -translate-x-12'
          }`}
          ref={sectionRef}>
            {/* Your existing content */}
            <h1 className='md:text-6xl sm:text-3xl text-5xl py-2 text-[#fff]'>
              Beyond <br/> Marketing Gallery
            </h1>
            <br />
            <p className='text-[#fff]'>
              Kami Siap Membantu Anda Menemukan Properti yang Tepat berbasis Virtual dengan Multiplayer
            </p>
            <NavLink
              to="/login"
              className='bg-[#fff] hover:bg-[#0C1B00] hover:text-[#fff] border-0 rounded-lg font-medium my-6 py-3 px-8 text-[#0C1B00] text-[20px] mr-auto'>
              Mulai Sekarang
            </NavLink>
          </div>
          
         
           {/* <img
           // id='parallax-img'
            className={`w-full h-full object-cover mx-auto my-1 rounded-lg ${
              inViewport ? 'opacity-100' : 'opacity-0'
            } transition-opacity duration-1000 animate-float`}
           
          /> */}
        </div>
       

      </div>
    </div>
  );
};

export default Ident2;
