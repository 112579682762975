import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { NavLink, useNavigate } from "react-router-dom";
import Logoa from "../assets/pict/logo/bahaso_logo_black.png";
import Logob from "../assets/pict/logo/bahaso_logo_white.png";
import Hexlogo from "../assets/pict/Hexaverse/logo.png";

const Hexnavbar = () => {
  const [nav, setNav] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [buttonBgColor, setButtonBgColor] = useState("white");
  const [buttonTxtColor, setButtonTxtColor] = useState("black");
  const [logo, setLogo] = useState(Logob);

  const navigate = useNavigate();

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setScroll(true);
      setButtonBgColor("black");
      setButtonTxtColor("white");
      setLogo(Logoa);
    } else {
      setScroll(false);
      setButtonBgColor("white");
      setButtonTxtColor("black");
      setLogo(Logob);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClose = () => setNav(false);

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <nav
      className={`fixed top-0 left-0 w-full  space-y-8 bg-white ${
        scroll ? "py-3" : "py-8"
      } 
      ${nav ? "z-50" : "z-10"} 
      ${scroll || nav ? "" : ""} transition-all duration-300 ease-in-out
      text-${scroll || nav || window.innerWidth <= 768 ? "white" : "white"} `}
    >
      <div
        className={`max-w-[1240px] px-2 md:px-4 py-1 mx-auto lg:bg-white md:bg-white lg:px-6 xl:px-8 flex justify-between items-center h-16 text-${
          scroll || nav || window.innerWidth <= 768 ? "white" : "white"
        } bg-white transition-all duration-300`}
      >
        <div className="flex items-center">
          <NavLink to="/">
          <img className={`h-[60px]`} src={Hexlogo} alt="Logo" />
          </NavLink>
          {/* <h1 className="text-2xl md:text-2xl cursor-pointer font-bold" onClick={() => navigate("/")}>
          Katulistiwa.
        </h1> */}
        </div>

        {sessionStorage.getItem("token") ? (
          <button
            className={`hover:text-white text-${buttonTxtColor} font-bold py-2 px-4 rounded-full transition-all duration-300 bg-${buttonBgColor}`}
            onClick={handleLogout}
          >
            Keluar
          </button>
        ) : (
          <NavLink to="/login">
            <button
              className={`hover:text-white text-${buttonTxtColor} font-bold py-2 px-4 rounded-full transition-all duration-300 bg-${buttonBgColor}`}
            >
              Masuk
            </button>
          </NavLink>
        )}
      </div>
      {nav && (
        <div className="md:hidden bg-white py-2 px-4">
          <Link
            to="home"
            smooth={true}
            duration={500}
            className="block text-black py-1"
            onClick={handleClose}
          >
            Home
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Hexnavbar;
