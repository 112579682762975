import React from 'react'
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer'
import gambar from '../assets/pict/Hexaverse/interior.jpg';

const Interiors = () => {
  return (
    <div className=''>
     <ReactPhotoSphereViewer src={gambar} height={'100%'} width={'100%'}  />
   </div>
  )
}

export default Interiors